import { useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
const PAYMENT_SERVICE_URL = import.meta.env.VITE_PAYMENT_SERVICE_URL

const CheckoutPage = () => {
    // const [email, setEmail] = useState('');
    // const [amount, setAmount] = useState(1000)
    let amount = 1000
    const [cardNumber, setCardNumber] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCvc] = useState('');
    const [name, setName] = useState('');
    const [country, setCountry] = useState('United States');
    const [zip, setZip] = useState<any>('');
    const [errors, setErrors] = useState<any>({})
    const navigate = useNavigate()

    console.log({
        errors, formData: {
            // email, 
            cardNumber, expiry, cvc, name, country, zip
        }
    })
    const handleClick = async () => {
        if (validateForm()) {
            let { exp_year, exp_month } = transformExp(expiry)
            exp_month
            exp_year
            let requestBody = {
                amount,
                currency: 'usd',
                paymentMethod: {
                    type: 'card',
                    card: {
                        number: cardNumber,
                        expMonth: exp_month,
                        expYear: exp_year,
                        cvc,
                        cardHolder: name
                    }
                },
                accountId: 'test',
            }
            try {
            let res = await axios.post(PAYMENT_SERVICE_URL + '/api/payments', requestBody)
            console.log(res)
            // Handle payment submission logic here
            console.log('Payment success');
            navigate('/success')
            } catch (err) {
                console.error(err)
                let newErrors = {
                    paymentStatus: 'payment fails'
                }
                setErrors(newErrors)
            }
        }
    };

    function transformExp(exp: string) {
        let exp_month, exp_year
        let arr = exp.split('/')
        exp_month = arr[0]
        exp_year = arr[1]
        exp_year = '20' + exp_year
        return { exp_month, exp_year }
    }

    function handleChangeExpir(value: string) {
        value = value.replace(/\D/g, '');
        if (value.length > 2) {
            value = value.slice(0, 2) + '/' + value.slice(2, 4);
        }
        setExpiry(value);
    }

    const validateForm = () => {
        let newErrors: any = {};
        if (!cardNumber.match(/^\d{16}$/)) {
            newErrors.cardNumber = "Card number must be 16 digits";
        }
        if (!name) {
            newErrors.name = "Cardholder name is required";
        }

        if (!cvc.match(/^\d{3,4}$/)) {
            newErrors.cvv = "CVV must be 3 or 4 digits";
        }
        if (!zip) {
            newErrors.zip = "Zip code cannot be empty"
        }
        if (!expiry) {
            newErrors.expiry = 'Expiration cannot be empty'
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center">
                        <span className="text-2xl font-bold mr-2">Synswi</span>
                        <span className="bg-yellow-200 text-yellow-800 text-xs font-semibold px-2 py-1 rounded">TEST MODE</span>
                    </div>
                    <div className="text-3xl font-bold">${amount / 100}</div>
                </div>

                <div>
                    <h2 className="text-xl font-semibold mb-4">Pay with card</h2>

                    {/* <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            
                        />
                    </div> */}

                    <div className="mb-4">
                        <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700 mb-1">Card information</label>
                        <div className="relative">
                            <input
                                type="text"
                                id="cardNumber"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="1234 1234 1234 1234"
                                value={cardNumber}
                                onChange={(e) => setCardNumber(e.target.value)}

                            />
                            <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex space-x-1">
                                <img src="/networks_image.jpg" alt="Visa" className="h-5" />
                            </div>
                        </div>
                        {errors.cardNumber && <p className="mt-2 text-sm text-red-600">{errors.cardNumber}</p>}

                    </div>

                    <div className="flex mb-4">
                        <div className="w-1/2 pr-2">
                            <input
                                type="text"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="MM / YY"
                                value={expiry}
                                onChange={(e) => handleChangeExpir(e.target.value)}

                            />
                            {errors.expiry && <p className="mt-2 text-sm text-red-600">{errors.expiry}</p>}

                        </div>

                        <div className="w-1/2 pl-2">
                            <input
                                type="text"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="CVC"
                                value={cvc}
                                onChange={(e) => setCvc(e.target.value)}
                            />
                            {errors.cvv && <p className="mt-2 text-sm text-red-600">{errors.cvv}</p>}

                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Cardholder name</label>
                        <input
                            type="text"
                            id="name"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Full name on card"
                            value={name}
                            onChange={(e) => setName(e.target.value)}

                        />
                        {errors.name && <p className="mt-2 text-sm text-red-600">{errors.name}</p>}

                    </div>

                    <div className="mb-4">
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">Country or region</label>
                        <div className="relative">
                            <select
                                id="country"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}

                            >
                                <option value="United States">United States</option>
                            </select>
                        </div>
                    </div>

                    <div className="mb-6">
                        <input
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="ZIP"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}

                        />
                        {errors.zip && <p className="mt-2 text-sm text-red-600">{errors.zip}</p>}

                    </div>

                    <button
                        onClick={handleClick}
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                    >
                        Pay
                    </button>
                    {errors.paymentStatus && <p className="mt-2 text-sm text-red-600">{errors.paymentStatus}</p>}

                </div>

                <div className="mt-6 text-center text-sm text-gray-500">
                    <p>Powered by synswi</p>
                    <div className="mt-2">
                        <a href="https://www.synswi.com/terms-of-service" target='_blank' className="text-blue-600 hover:underline mr-4">Terms</a>
                        <a href="https://www.synswi.com/privacy" target='_blank' className="text-blue-600 hover:underline">Privacy</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutPage;