import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import CheckoutPage from '@/pages/checkout/CheckoutPage.tsx'
import PaymentSuccessPage from "./pages/payment_success/PaymentSuccess";
export const router = createBrowserRouter([
    {
        path: '/',
        element: <CheckoutPage /> // this page is payment
    },
    {
        path: '/success',
        element: <PaymentSuccessPage />
    }
]);

function Routes() {
    return (
        <RouterProvider router={router} />
    );
}

export default Routes;
