import { useState, useEffect } from 'react';

const PaymentSuccessPage = () => {
  const [paymentDetails, setPaymentDetails] = useState({
    amount: 0,
    currency: 'USD',
    merchantName: '',
    paymentId: '',
    timestamp: ''
  });
  useEffect(() => {
    // Simulating fetching payment details from an API
    const fetchPaymentDetails = async () => {
      // Replace this with actual API call
      const mockData = {
        amount: 100.00,
        currency: 'USD',
        merchantName: 'Example Merchant',
        paymentId: 'TXN123456789',
        timestamp: new Date().toISOString()
      };
      setPaymentDetails(mockData);
    };

    fetchPaymentDetails();

  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
      <div className="bg-white shadow-xl rounded-lg p-8 max-w-md w-full">
        <div className="text-center">
          <svg className="mx-auto h-12 w-12 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          <h2 className="mt-4 text-3xl font-extrabold text-gray-900">Payment Successful!</h2>
          <p className="mt-2 text-sm text-gray-600">Your payment has been processed successfully.</p>
        </div>

        <dl className="mt-8 space-y-4">
          <div className="flex justify-between">
            <dt className="text-sm font-medium text-gray-500">Amount Paid:</dt>
            <dd className="text-sm font-bold text-gray-900">{`${paymentDetails.currency} ${paymentDetails.amount.toFixed(2)}`}</dd>
          </div>
          <div className="flex justify-between">
            <dt className="text-sm font-medium text-gray-500">Merchant:</dt>
            <dd className="text-sm text-gray-900">{paymentDetails.merchantName}</dd>
          </div>
          <div className="flex justify-between">
            <dt className="text-sm font-medium text-gray-500">Transaction ID:</dt>
            <dd className="text-sm text-gray-900">{paymentDetails.paymentId}</dd>
          </div>
          <div className="flex justify-between">
            <dt className="text-sm font-medium text-gray-500">Date & Time:</dt>
            <dd className="text-sm text-gray-900">
              {new Date(paymentDetails.timestamp).toLocaleString()}
            </dd>
          </div>
        </dl>

        <div className="mt-8">
          <p className="mt-2 text-sm text-center text-gray-600">
            You can safely close this window
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;