import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
// import store from './redux/store.jsx';
// import { Provider } from 'react-redux';
import Routes from './Routes.jsx';
// import NotificationContainer from './notificationContainer.jsx';
ReactDOM.createRoot(document.getElementById('root')).render(
    // <React.StrictMode>
        // <Provider store={store}>
            // <NotificationContainer />
            <Routes/>
        // </Provider>
    // </React.StrictMode>,
)
